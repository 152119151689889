.central-greeting-down-arrow {
    bottom: 0;
    animation: loadingArrow 1.5s cubic-bezier(0.68, -0.55, 0.27, 1) forwards;
}





.app {
    opacity: 0;
    position: relative;
    animation: bubbleAnimation 1s cubic-bezier(0.68, -0.55, 0.27, 1) both;
    transform-origin: bottom center;
    will-change: transform;
    perspective: 800px;
}

.app.loaded {
    opacity: 1;
    top: 15px;
}

@keyframes bubbleAnimation {
    0% {
        transform: scale(0) translate3d(0, 0, 0);
        opacity: 0;
        bottom: -70px;
        filter: blur(0px);

    }

    /* 25% {
      transform: scale(0.5) translate3d(0, 0, 0);
      opacity: 0.4;
      filter: blur(1px);
    }
  
    50% {
      transform: scale(0.5) translate3d(0, 0, 0);
      opacity: 0.7;
      filter: blur(2px);
      z-index: 1000;

    }
  
    75% {
      transform: scale(0.9) translate3d(0, 0, 0);
      opacity: 0.9;
      filter: blur(1px);
      z-index: 1000;

    } */

    100% {
        transform: scale(1) translate3d(0, 0, 0);
        opacity: 1;
        bottom: -10px;
        filter: blur(0);
        /* Remove blur effect */
        z-index: 100;

    }
}


.thumbnail-overlay svg {
    height: 307px;
    width: 320px;
}



.thumbnail-overlay {
    position: absolute;
    top: 72px;
    left: 0;
    width: 319px;
    height: 340px;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.thumbnail-overlay img {
    max-width: 100%;
    max-height: 100%;
}

.new-background-video-container:hover .thumbnail-overlay {
    opacity: 0;
    /* Hide the overlay on hover */
}

.start-central-navbar-backside-color {
    display: none;
}

.central-greeting-wrapper {
    position: relative;
    width: 100%;
    max-width: 610px;
    display: none;
    min-height: 126px;
}

.desk_home_video_wrapper {
    position: absolute;
    height: 100%;
    /* width: 60%; */
    top: 65px;
    left: -10px;
}

.desk_home_video_container {
    height: 100%;
}

.start-central-greeting-wrapper .central-greeting-container-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.start-central-greeting-wrapper {
    display: flex !important;
    /* margin-left: auto;
    margin-right: auto; */
}

.central-greeting-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    width: 100%;
    background: #FFF;
    stroke-width: 1px;
    stroke: #D4E3F9;
    filter: drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.25));
    padding: 10px 10px 14px;
    position: absolute;
    z-index: 10;
    gap: 2px;
    max-width: 520px;
    min-height: 110px;
}

.central-greeting-user-name,
.central-greeting-text {
    color: #1A3054;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}


.central-greeting-user-name {
    font-weight: 700;
}

.central-greeting-text {
    font-weight: 500;

}

.central-greeting-user img {
    padding-bottom: 1px;
}

.central-greeting-img {
    width: 74px;
}

.central-greeting-question {
    display: flex;
    align-items: center;
    gap: 5px;
}

.central-column-btn-right {
    width: 130px;
    height: 30px;
}


.central-greeting-ques {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;

}

.central-greeting-question img {
    width: 25px;
}

.central-greeting-down-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #fff;
    margin: 0 auto;
    position: absolute;
    left: -3px;
    transform: rotate(40deg);
    z-index: 100;
}

.new-background-video-container {
    position: relative;
    width: 100%;
    height: 382px;
    background: transparent !important;
    border-radius: 16px 0px 0px;


}

.desk_home_video_container {
    width: 100%;
}

.new-background-video-container-chat-video {
    width: 100%;
    object-fit: cover;
    max-height: 700px;
}

.main-three-colum-layout-container {}

.new-background-video-container-chat {
    width: 100%;
    height: 100%;
    background: transparent !important;
    border-radius: 16px 0px 0px;
    display: flex;

}

.left-column .video-wrapper .new-background-video-container-chat video {
    height: 100%;
    /* bottom: 40px; */
    top: auto;
}

.main-three-colum-layout .mobile-navbar-items li a .user-kps {
    width: 110% !important;

}

.main-three-colum-layout .mobile-navbar-items .user-li {
    border-radius: 16px !important;
}


.main-three-colum-layout .mobile-navbar-items .menu-li {
    background: none;

}

.main-three-colum-layout .mobile-navbar-items li a .menu-icon {
    width: 90% !important;
    margin-left: 0px;
}

/* start page */
.start-left-column {
    /* display: none !important; */
}

.start-menu-li {
    border-radius: 25px !important;
}

.start-central-column {
    max-width: 1300px !important;
    border-radius: 12px !important;
    display: flex !important;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
    background: transparent;
    flex-direction: column;
    padding-left: 3px;
    padding-right: 3px;
    height: 100%;
    padding-top: 38px;
    /* position: relative !important; */

    padding-bottom: calc(100vh - 750px);
}



.main-three-colum-layout .start-right-column {
    position: fixed !important;
    background-color: transparent !important;
    background: #e4efff !important;

}

.start-cental-video-wrapper {
    /* position: absolute; */
    position: relative;
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    top: 20px;
    z-index: 0;

}

.start-central-video-display {
    display: none;
}

.start-central-logo {
    margin: -18px 0px 10px;
    position: relative;
    z-index: 10000;

}

.start-video {
    width: 780px !important;
    margin-right: 0px;
    margin-top: 50px;
    height: 438px;
}


.start-mobile-text {
    color: #1E56A6;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 118.519% */
}

.start-bg-color {
    background: #f5f8ff !important;
}

.start-main-three-colum-layout {
    align-items: center;
    background-color: #eff0f5  !important;
    justify-content: center !important;
}

.start-mobile-text-wrapper {
    display: block !important;
    position: relative;
    z-index: 0;
}

.start-mobile-text-hide {
    display: none;
}

.start-mobile-text {
    margin: 0px;
}

.start-nav-img {
    display: none;
}

.start-nav-li {
    background: none !important;
}

.start-mobile-navbar-items {
    background: transparent !important;
}

.start-mobile-text-container {}

.center-column {
    display: flex;
}

.start-main-inner-container {
    box-shadow: none !important;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++ */




.mobile_1v3_nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    display: flex;
    width: 100%;
    -webkit-backdrop-filter: blur(20px) brightness(1);
    backdrop-filter: blur(20px) brightness(1);
}

.toggle_menu {}


.toggle_menu_desk {
    width: 45px;
}

.mobile_1v3_nav_wrapper {
    padding: 10px;
    width: 100%;


}

.mobile_1v3_nav_profile {
    position: relative;
    width: 40px;
}



.mobile_1v3_side_nav {
    position: absolute;
    top: 0px;
    height: 100%;
    /* padding: 10px; */
    z-index: 1000;
    width: 100%;
    left: calc(-100vw);
    transition: left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.start_mobile_1v3_side_nav {
    /* display: none; */
}

.side_nav_menu_item .sharebtn-wrapper {
    display: flex;
    justify-content: unset;
}

.mobile_1v3_side_nav_open {
    left: 0px;
    transition: left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.mobile_1v3_side_nav_container {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.mobile_1v3_side_nav_profile a img {
    width: 70px;
    padding: 10px;
}

.side_nav_menu_container {
    width: 82vw;
    border-radius: 20px;
    background: #ffffffb3;
    -webkit-backdrop-filter: blur(2px) brightness(100%);
    backdrop-filter: blur(2px) brightness(100%);
}

.side_nav_menu_item_back a img {
    padding: 20px;
    max-width: 75px;
    width: 75px;
}

.side_nav_menu_item_wrapper .side_nav_menu_item {
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
}

.side_nav_menu_item {
    display: flex;
    gap: 20px;
}

.side_nav_menu_item a {
    display: flex;
    gap: 20px;
    text-decoration: none;
    width: 100%;
}

.side_nav_menu_item_bottom_text {
    align-items: center;
    color: #00000052;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 400;
    justify-content: center;
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
}

.side_nav_menu_item_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    color: #0a2b72;

    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;

}

.side_nav_menu_item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    justify-content: space-between;
}

.side_nav_menu_item_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.side_nav_menu_item_back {
    display: flex;
    justify-content: flex-end;
}

.mobile_1v3_side_nav_wrapper {
    height: 100%;
}





.mobile_1v3_nav_toggle .toggle_menu_wrapper img {
    width: 45px;
}

.mobile_1v3_nav_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile_1v3_nav_profile a img {
    max-width: 40px;
}


.deskNav {
    /* display: none!important; */
    left: -82px !important;
    transition: left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-column-logo {
    position: absolute;
    z-index: 100000;
    left: 27px;
    top: 18px;
    display: flex;
    gap: 20px;
}


.left-column-vertical-buttons {
    position: absolute;
    z-index: 10000;
    left: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transition: left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.vertical-button-img {
    max-width: 58px;
    display: inline-block;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);

}

.vertical-button-img:hover {
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);

}

.sharebtn .sharebtn-img {
    border: none;
    padding: 5px 6px 4px;
    border-radius: 10px;
    /* opacity: 0.75; */
    background: transparent;
}

.sharebtn .sharebtn-img:hover {
    opacity: 1;

}

.share {
    cursor: pointer;
}



.sharebtn-wrapper {
    display: flex;
    justify-content: center;

}


.sharebtn-inner {
    display: flex;
    position: relative;
    justify-content: center;
}

.side_nav_menu_item button {
    padding: 0px !important;
}

.vertical-button a {
    text-decoration: none;

}

.side_nav_text {
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #fff;
    text-decoration: none;

}

@media (min-width: 767px) {
    .mobile_1v3_side_nav_open {
        display: none;
    }

    .mobile_1v3_nav {
        display: none;

    }

    .start-central-video-display {
        display: none !important;
    }

    .app.loaded {
        top: -85px;
    }

    @keyframes loadingArrow {
        0% {
            bottom: 0;
        }
    
        100% {
            bottom: -4px;
        }
    }

}

.toggle_menu_wrapper {
    align-items: center;
    background: #0000000d;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 10px;
    width: 48px;
}

.left-column-logo a {
    align-items: center;
    display: flex;
}

.toggle_menu_desk {
    height: 30px;
    transform: scaleX(-1);
    width: 30px;
}

.left-column-logo {
    display: flex;
    gap: 55px;
    left: 27px;
    position: absolute;
    top: 18px;
    z-index: 100000;
}

.side_nav_text {
    color: #0a2b72;
    font-family: Rubik, sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.main-three-colum-layout .menu-logo-kps {
    width: 290px !important;
    max-width: 305px !important;
}



@media (max-width: 767px) {
    @keyframes loadingArrow {
        0% {
            bottom: 0;
        }
    
        100% {
            bottom: -14px;
        }
    }
    .central-greeting-down-arrow {
        left: auto;
        transform: rotate(0deg);
    }
    .start-cental-video-wrapper {
        top: 30px;
    }
    .central-greeting-ques {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

    }

    .desk_home_video_wrapper {
        display: none;
    }

    .new-background-video-container {
        display: flex;
        justify-content: center;
    }

    .central-greeting-container {
        min-height: 74px;
    }

    .central-greeting-wrapper {
        max-width: 350px;
        min-height: 74px;
    }

    .central-greeting-text {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        text-align: left;

    }

    .central-greeting-img {
        width: 52px;
    }

    .start-central-column {
        align-items: center;
    }

    .main-three-colum-layout .menu-logo-kps {
        width: 205px !important;
        max-width: 305px !important;
    }

    .mobile_1v3_nav_logo {
        display: flex;
        align-items: center;
    }

    .vertical-button-img {
        max-width: 40px;
    }

    .side_nav_menu_item .sharebtn-wrapper .sharebtn-inner {
        margin-left: 0px;
    }

    .knightingale_avatar_bar_wrapper {
        height: 60px;
    }

    .main-inner-container {
        background: #E4EFFF80 !important;
        box-shadow: none;
        /* backdrop-filter: blur(0); */

    }


    .start-main-background-container {
        height: 290px !important;
        top: 0px;
        position: relative;
    }

    .start-mobile-text-wrapper {
        margin-bottom: 15px;
    }

    .start-main-logo {
        width: 250px;
    }

    .loading-indicator {
        /* margin-bottom: -18px !important; */

    }

    .central-navbar-backside-color {
        width: 100%;
        height: 56px;
        top: 0;
        background: red;
        position: absolute;

    }

}



@media (min-width: 995px) {
    .mobile-navbar-items {
        display: none !important;
    }

    .right-column {
        border-radius: 0px !important;

    }
}

@media (max-width: 995px) {
    .mobile-navbar-items {
        /* background: #fff!important; */

    }
}

@media (min-width: 992px) {
    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;
        flex: 0 0 auto;
        top: 0px;
        bottom: auto !important;
        left: auto !important;
        display: flex;
        align-items: center;
        z-index: 999;

    }
}

.user-login-img {
    margin: auto 0 !important;
}

.main-three-colum-layout .navbar-items .user-login-img a img {
    width: 35px;
    /* margin-right: -45%; */
}



.right-column .navbar .navbar-items .menu-logo-kps-wrapper {
    height: 100% !important;
    padding: 0px !important;
    background: #fff !important;
    margin: 10px 0px 5px 0px !important;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.start-menu-logo-kps-wrapper {
    display: none;
}

.start-logo-profile {
    /* margin-top: 10px!important; */
}



.bold-text {
    font-weight: 800;
}


.right-column .navbar .navbar-items .start-menu-logo-wrapper {
    margin-left: 0px !important;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .main-three-colum-layout .navbar-items {

        max-width: 1200px;
    }

    .user-login-img {
        /* width: 10% !important; */
    }
}

@media screen and (min-width: 1280px) {
    .output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
        max-width: 70ch !important;
    }

    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
        max-width: 70ch !important;
    }
}


.start-logo-profile-img {
    /* margin-right: -38% !important; */
}


.bacground-client-msg-bubble {
    margin-bottom: 0px !important;
}


.knightingale_avatar_bar img {
    width: 100%;
    position: relative;
    bottom: -4px;
    max-width: 55px;
    /* margin-top: 10px; */
}

.knightingale_avatar_bar_wrapper {
    height: 77px;
}

.knightingale_avatar_bar {
    height: 55px;
    display: flex;
    justify-content: left;
    /* padding-left: 20px; */
}

@media screen and (min-width: 767px) {
    .knightingale_avatar_bar {
        display: none;
    }
}


@media (max-width: 767px) {

    .knightingale_avatar_bar_wrapper {
        height: 60px;
    }
}