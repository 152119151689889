.personlization-form-wrapper {
  background-color: #e9f2ff;
  width: 100%;
  height: calc(100vh - 48px);
  justify-content: center;
  display: flex;
  overflow-y: scroll;
}

.personlization-form-wrapper-outer {
  padding-top: 47px;
  width: 100%;
}

.profile-link-wrapper img {
  width: 16px;
}

.personalization-form-inner-wrapper {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  height: 132%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

option {
  color: red !important;
}

.footer-set {
  height: 30px !important;
}

.personalization-form-container {
  margin: auto 10px;
  padding: 40px 80px 30px;
  background: #fff;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .personalization-form-container {
    padding: 40px 30px 30px;
    margin: 10px 10px 30px;
  }

  .profile-wrapper {
    left: -163px;
  }
  .footer-set {
    height: 63px !important;
  }
}

/* .profile-link a{
  display: flex!important;
  justify-content: center!important;
} */

.profile-link {
  margin-right: -192px
}

/* .form-input-bdy{
text-align: center;
}

.form-input-bs{
  text-align: center;
} */

.personalization-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.personalization-form-header {
  text-align: center;
  margin-bottom: 30px;
}

.personalization-form-header h3 {
  margin-top: 0;
  color: #1a305e;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.form-label {
  color: #1A3054;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.form-input,
.form-select,
.form-textarea {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: #000;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fafafa;
  font-size: 15px;
  line-height: 1.8;
}

.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  outline: none;
  border-color: #4a90e2;
}

.profile-label-input-wrapper-button {
  text-align: center;
}

/* .form-input-bs{
  text-align: center;
} */

.form-button {
  background: #2175f3;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
  grid-column: 1/3;
  width: 65%;
}

.form-button:hover {
  background: linear-gradient(to right, #407bbf, #5c94cc);
}