/* ShareWindow.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.share-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 96%;
  max-width: 550px;
}

.share-modal input {
  width: calc(100% - 18px);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  font-family: roboto;
}

.copy-button,
.close-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0px 0px 0px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}
.media-container{
  display: flex;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.close-button {
  background-color: transparent;
}

.close-button img {
  width: 32px;
    opacity: 1;
    padding: 5px;
    border-radius: 10px;
}

.close-button img:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.15);
}

.copy-button img {
  width: 16px;
  /* Adjust the size as needed */
  height: 16px;
  margin-right: 5px;

}

.social-media-title{
  color: #000;
  text-align: center;
  font-family: "Roboto",sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 11px;
}
.media-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: auto;
  width: 100%;
}

.media-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 87px;
    width: 77px;
}

.media-inner:hover{
  border-radius: 15px;
background: #EFF4FC;
}

.media-inner-img {
  padding: 8px;
  width: 64px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
}

.media-inner-img img {
  height: 40px;
}


.media-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* width of the entire scrollbar */
}

.media-wrapper::-webkit-scrollbar-track {
  background: #cfd4dd;
  border-radius: 20px;
}

.media-wrapper::-webkit-scrollbar-thumb {
  background-color: #dee7f5;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #dee7f5;
  /* creates padding around scroll thumb */
  width: 8px;
}

.copy-input {
  display: flex;
  padding-top: 20px;
  padding-bottom: 30px;
}

.share-close {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}

.copy-link {
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px #00000040 inset;
  margin-left: 10px;
  display: flex;
  border: none;
  padding-left: 4px;
  gap: 5px;
  cursor: pointer;
  min-width: 130px;
  justify-content: center;
}

.copy-img {
  display: flex;
}

.copy-text {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.copy-link:hover {
  background: #f5f5f5;
}
.copytool {
  position: relative;
}

.copytool .copytooltext {
 
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 7px;
  position: absolute;
  z-index: 1;
  bottom: 104%;
  left: -5%!important;
}

.copytool .copytooltext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

@media (max-width: 767px) {
.media-wrapper {
  flex-direction: column;
}

.media-container {
  justify-content: space-around;
}
.close-button img {
 
  position: relative;
  top: -6px;
  right: -6px;
}
}